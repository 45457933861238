import { addons } from '@storybook/addons';
import { create } from '@storybook/theming/create';

addons.setConfig({
  theme: create({
    base: 'light',
    brandTitle: 'tackle',
    fontBase: 'Poppins',
    brandUrl: "https://github.com/jrmoore117",
  }),
});
